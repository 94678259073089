
import {defineComponent} from 'vue';
import {AuthStates} from '@/store/auth';
import {useAuth} from '@/composables/useAuth';

export default defineComponent({
  name: 'LoginView',
  setup() {
    const {authState, login, userIdText, passwordText, shortcodeText, validation} = useAuth();

    return {authState, AuthStates, login, userIdText, passwordText, shortcodeText, validation};
  },
});
